$instagram-gradient-start: #f09433;
$instagram-gradient-middle1: #e6683c;
$instagram-gradient-middle2: #dc2743;
$instagram-gradient-middle3: #cc2366;
$instagram-gradient-end: #bc1888;

footer {
    padding: 1rem;
    background-color: var(--background-color-1);

    .container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;

        section {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h2 {
                font-size: 1.05rem !important;
                font-weight: var(--headings-font-weight);
                color: var(--font-color);
                margin-bottom: 0.5rem;
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;

                li {
                    margin-bottom: 0.5rem;
                    font-weight: var(--headings-font-weight);
                    color: var(--font-color);
                    text-decoration: none;

                    &:hover {
                        color: var(--primary-color);
                        text-decoration: underline;

                        a {
                            color: var(--primary-color) !important;
                        }
                    }
                }
            }

            .social-links {
                display: flex;
                gap: 10px;
                margin: 1.5rem 0;

                a {
                    padding: 0.5rem;
                    color: var(--font-color);
                    text-decoration: none;
                    font-size: 1.5rem;
                    transition: all 0.2s ease-in-out;
                }

                .instagram {
                    svg {
                        border-radius: 0.5rem;
                    }

                    &:hover {
                        svg {
                            color: #ffffff;
                            background: linear-gradient(to right, $instagram-gradient-start, $instagram-gradient-middle1, $instagram-gradient-middle2, $instagram-gradient-middle3, $instagram-gradient-end);
                        }
                    }
                }

                .github {
                    margin: -0.35rem;

                    svg {
                        padding: 0.2rem;
                        border-radius: 1.5rem;
                        border: 3px solid transparent;
                    }

                    &:hover {
                        svg {
                            border: 3px solid var(--font-color)
                        }
                    }
                }

                .linkedin {
                    &:hover {
                        svg {
                            color: #2D64BC
                        }
                    }
                }

                .twitter {
                    &:hover {
                        svg {
                            color: #4A9AE4;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 768px) {
    footer {
        padding: 1rem 2rem;

        .container {
            flex-direction: column;

            section {
                margin-bottom: 1rem;
                align-items: flex-start;

                ul {
                    align-items: flex-start;
                }
            }
        }
    }
}