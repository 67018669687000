@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Italic';
    font-style: italic;
    src: url('../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}