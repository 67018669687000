.author {
    display: flex;
    align-items: center;
    gap: 1rem;

    .image {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
    }

    .meta {
        gap: 0.375rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
            font-weight: 700 !important;
            color: var(--primary-color) !important;

            &:hover {
                text-decoration: underline !important;
            }
        }

        .title {
            font-weight: 400;
            font-size: 0.875rem;
            color: var(--font-color);
        }
    }
}