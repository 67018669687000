.privacy {
    text-align: left;
    padding: 0.5rem 4rem;
    color: var(--font-color);

    ul {
        padding: 0 2.5rem !important;

        li {
            display: list-item;
            line-height: 2rem;
            list-style: unset !important;
        }
    }

    .link {
        color: var(--primary-color) !important;
        text-decoration: underline !important;
    }
}

@media (max-width: 768px) {
    .privacy {
        padding: 1rem 2rem;
    }
}