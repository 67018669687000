.post {
    text-align: left;
    color: var(--font-color);

    .title {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    .meta {
        margin: 1.25rem 0 .75rem 0;

        span {
            color: var(--font-color);
        }
    }

    .authors {
        margin-bottom: 2.75rem;
    }

    .tags {
        display: flex;
        gap: 0.5rem;
        margin-top: 4rem;
        align-items: center;
    }
}