:root {
    --primary-color: #00bfff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
    --white-color: #fff;
    --black-color: #000;
    --body-color: #212529;
    --font-size-base: 1rem;
    --font-weight-base: 400;
    --headings-font-weight: 600;
    --text-muted-color: #6c757d;
    --border-color: #e9ecef;
    --border-radius: 0.25rem;
    --box-shadow: 0 0.15rem 1.75rem 0 rgba(0, 0, 0, 0.1);
    --transition: all 0.2s ease-in-out;
}

.theme-light {
    --primary-color: #00bfff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --font-color: #000000;
    --button-color: #ffffff;
    --font-color-alternate: #ffffff;
    --background-color-1: #ffffff;
    --background-color-2: #eeeeee;
    --scrollbar-background-color: #cecece;
    --scrollbar-thumb-color: #aaaaaa;
    --box-shadow: 0 0.15rem 1.75rem 0 rgba(0, 0, 0, 0.1);
    --transition: all 0.2s ease-in-out;
}

.theme-dark {
    --primary-color: #00bfff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --font-color: #ffffff;
    --font-color-alternate: #000000;
    --button-color: #1d1d1d;
    --background-color-1: #252525;
    --background-color-2: #2C2C2D;
    --scrollbar-background-color: #1a1a1a;
    --scrollbar-thumb-color: #6B6B6B;
    --box-shadow: 0 0.15rem 1.75rem 0 rgba(0, 0, 0, 0.1);
    --transition: all 0.2s ease-in-out;
}