@import url('./assets/styles/variables.scss');
@import url('./assets/styles/reset.scss');
@import url('./assets/styles/fonts.scss');

html {
  height: 100%;
  overflow: hidden;
  font-size: 0.95rem;
  scroll-behavior: smooth;
  font-weight: var(--font-weight-base);
  font-family: 'Montserrat', sans-serif;

  body {
    margin: 0;
  }
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 1rem;
  /* Width of the entire scrollbar */
  background: var(--scrollbar-background-color);
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  width: 0.9rem;
  background: var(--scrollbar-background-color);
}

::-webkit-scrollbar-thumb {
  width: 3.125rem;
  padding: 0.25rem;
  -webkit-border-radius: 0.45rem;
  background-clip: padding-box;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  background-color: var(--scrollbar-thumb-color);
}


body {
  height: 100%;

  #root {
    height: 100%;

    .App {
      height: 100%;
      display: flex;
      overflow-y: auto;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      background: var(--background-color-2);

      h1 {
        color: var(--font-color);
      }

      h2 {
        font-size: 1.5rem;
        font-weight: var(--headings-font-weight);
        color: var(--font-color);
      }

      h3 {
        font-size: 1.25rem;
        font-weight: var(--headings-font-weight);
        color: var(--font-color);
      }

      a {
        color: var(--font-color);
        font-weight: var(--font-weight-base);
        text-decoration: none
      }

      p {
        color: var(--font-color)
      }

      ul {
        padding: unset;
      }

      li {
        list-style: none;
      }

      .primary-button {
        border: none;
        display: flex;
        gap: 0.5rem;
        cursor: pointer;
        padding: 0.75rem;
        border-radius: .5rem;
        align-items: center;
        color: var(--font-color);
        justify-content: space-around;
        font-family: 'Montserrat', sans-serif;
        background: var(--button-color);
        font-weight: var(--headings-font-weight);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        svg {
          font-size: 0.85rem;
          color: var(--font-color);
        }

        &:hover {
          background: var(--font-color);


          color: var(--font-color-alternate);


          svg {
            color: var(--font-color-alternate);
          }
        }
      }
    }
  }
}