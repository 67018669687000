.blog {
    padding: 3rem;

    .post {
        .title {
            margin: 0;
        }
    }

    p {
        line-height: 2rem;
    }
}