.sidebar-wrapper {
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: left 0.4s, opacity 0.4s, visibility 0.4s;

    &.open {
        opacity: 1;
        visibility: visible;

        .sidebar-underlay {
            opacity: 1;
        }

        .sidebar {
            left: 0;
            opacity: 1;
        }
    }

    .sidebar-underlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        background: rgba(0, 0, 0, 0.45);
        transition: opacity 0.4s;
    }

    .sidebar {
        z-index: 10;
        height: 100%;
        width: calc(100% - 8rem);
        position: absolute;
        left: -100%;
        opacity: 0;
        background: var(--background-color-1);
        transition: left 0.4s, opacity 0.4s;

        header {
            padding: 1rem;
            box-shadow: 0 1px 2px 0 #0000001a;

            .logo-section {
                display: flex;
                gap: 0.5rem;
                flex-direction: row;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                }

                h1 {
                    font-size: 1rem;
                    color: var(--font-color);
                    font-weight: var(--headings-font-weight);

                    &:hover {
                        color: var(--primary-color) !important;
                    }
                }
            }

            .close {
                cursor: pointer;

                svg {
                    font-size: 1rem;
                    color: var(--font-color);
                }
            }
        }

        .menu-section {
            ul {
                gap: 0.25rem;
                display: flex;
                flex-direction: column;
                padding: 0 1.5rem !important;

                li {
                    text-align: left;
                    padding: 0.5rem;
                    border-radius: 0.25rem;
                    cursor: pointer;

                    &:hover {
                        background: var(--background-color-2);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .sidebar-wrapper {
        &.open {
            display: none;
        }
    }
}