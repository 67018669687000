.hero {
    display: flex;
    height: 100%;
    padding: 1rem;
    min-height: 30rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-weight: 600;
    }

    .blast {
        font-weight: 500;
        color: var(--primary-color);
    }

    .subtitle {
        line-height: 3.25rem;
        font-weight: 400 !important;
        font-size: 1rem !important;
    }
}

@media (max-width: 768px) {
    .hero {
        .subtitle {
            padding: 0 3rem;
        }
    }
}