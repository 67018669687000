header {
    top: 0;
    padding: 1rem;
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color-1);

    .left-section {
        gap: 1.75rem;
        display: flex;
        align-items: center;

        .logo-section {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .menu-button {
                cursor: pointer;
                display: none;

                .menu-icon {
                    font-size: 1.5rem;
                    color: var(--font-color);
                    margin: 0 0.5rem 0 0;
                }
            }

            img {
                width: 32px;
                height: 32px;
            }

            h1 {
                font-size: 1rem;
                color: var(--font-color);
                font-weight: var(--headings-font-weight);

                &:hover {
                    color: var(--primary-color) !important;
                }
            }
        }

        nav {
            ul {
                display: flex;
                align-items: center;

                li {
                    margin-right: 1.25rem;
                    list-style: none;

                    a {
                        cursor: pointer;
                        text-decoration: none;
                        font-weight: var(--headings-font-weight);
                    }

                    &:hover {
                        a {
                            color: var(--primary-color) !important;
                        }
                    }
                }
            }

        }
    }

    .right-section {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    header {
        .left-section {
            .logo-section {
                .menu-button {
                    display: block;
                }
            }

            nav {
                display: none;
            }
        }
    }
}